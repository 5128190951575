//@import 'src/scss/_bootstrap-customization-colors';
//@import 'src/scss/colors';
body {
    color: #164984;
}

field {
    border: #164984 1px solid !important;
}

.busy_loader {
    // z-index: 1;
    transition: opacity 0.1s ease-out, height 0.5s ease-out;
    position: fixed;
    background-color: rgb(240, 240, 240);
    /*dim the background*/
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    color: black;
    text-align: center;
    svg {
        // fill: $benefia-red;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -20px;
        margin-left: -25px;
        width: 50px;
        height: 40px;
        text-align: center;
        font-size: 10px;
    }
}

.col {
}

.ml-10 {
    margin-left: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.light-blue {
    background-color: #f6f8fa;
    color: #1f4684;
    padding-top: 10px;
    margin-left: 2px;
    margin-right: 15px;
    margin-bottom: 2px;
}

.blue {
    background: #dee5ee;
    color: #1f4684;
    // padding-top: 5px;
    margin-left: 15px;
    margin-bottom: 2px;
    line-height: 60px;
}

.dark-blue {
    background-color: #1f4684;
    color: #ffffff;
}

.light-blue-only {
    background-color: #f6f8fa;
    color: #1f4684;
}

.nav-item {
    background-color: #1f4684;
    color: #ffffff;
}

a:hover.nav-item {
    background-color: #1f4684;
    color: #ffffff;
}

.nav-item.show {
    color: #164984 !important;
}

.nav-link.active {
    color: #164984 !important;
}

.TabButtons {
    text-align: right;
    margin-top: 15px;
}

.react-datepicker-popper {
    z-index: 10;
}

.tab-content {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}

.ouTab {
    padding: 20px 0 20px 0;
}

.rowSubOu {
    border-bottom: 1px solid #dee2e6;
    padding: 4px;
}

fieldset {
    border: 1px groove #164984 !important;
}

fieldset.listAdder {
    border: 1px solid #164984 !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 1.5em 0 1.5em 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px #dee2e6;
    box-shadow: 0px 0px 0px 0px #dee2e6;
}

legend.listAdder {
    width: inherit;
    padding: 0 10px;
    border-bottom: none;
    font-size: 16px;
}

.light-blue-color {
    background-color: #f6f8fa;
    color: #1f4684;
}

.blue-color {
    background: #dee5ee;
    color: #1f4684;
}

.konkurencjaDiv {
    font-size: 20px;
    font-weight: bold;
}

.zawodyWynikiTable {
    // border: #164984 1px solid;
    margin: 0px 0 40px 0;
}

.zawodyWynikiTable thead tr {
    background-color: #1f4684;
    background-image: -webkit-gradient(linear, left 0%, left 100%, from(#1f4684), to(#1f4684));
    background-image: -moz-linear-gradient(top, #1f4684 0%, #1f4684 100%);
    background-image: linear-gradient(to bottom, #1f4684 0%, #1f4684 100%);
    border-color: #1f4684;
    color: white;
}

tr:nth-child(even) {
    background: #dee5ee;
    color: #1f4684;
}

tr:nth-child(odd) {
    background-color: #f6f8fa;
    color: #1f4684;
}

.zawodyWynikiTableseria {
    width: 50px;
    text-align: center;
}

.zawodyWynikiTableImie {
    width: 100px;
}

.zawodyWynikiTableNazwisko {
    width: 150px;
}

.zawodyWynikiTableMiejsce {
    width: 50px;
}

.zawody-spinner {
    position: fixed;
    left: 50%;
    top: 50%;
}

.ZawodnikName {
    font-size: 20px;
    font-weight: bold;
}
// .bestTableZawodySpan {
//     width: 80px;
// }
// .bestTableMiejsceSpan {
//     width: 80px;
// }
// .bestTableDataSpan {
//     width: 80px;
// }
.TableZawodySpan {
    width: 80px;
}

.TableDataSpan {
    width: 80px;
}

.zawodyWynikiTable span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

// .zawodyWynikiTable {
//     font-size: 10px;
// }
// .TableZawodySpan {
//     width: 150px;
// }
// .TableDataSpan {
//     width: 100px;
// }

@media screen and (min-width: 200px) and (max-width: 460px) {
    .zawodyWynikiTable {
        font-size: 10px;
    }
    .TableZawodySpan {
        width: 70px;
    }
    .TableDataSpan {
        width: 40px;
    }
    .searchCard {
        width: 300px;
        font-size: 8px;
        h4 {
            font-size: 15px;
            margin-bottom: 0px;
        }
    }
}
@media screen and (min-width: 460px) and (max-width: 576px) {
    .zawodyWynikiTable {
        font-size: 10px;
    }
    .TableZawodySpan {
        width: 100px;
    }
    .TableDataSpan {
        width: 50px;
    }
    .searchCard {
        width: 200px;
        font-size: 8px;
    }
}

@media screen and (min-width: 768px) {
    .TableZawodySpan {
        width: 150px;
    }
    .TableDataSpan {
        width: 100px;
    }
    .searchCard {
        width: 320px;
        font-size: 12px;
        min-height: 180px;
        h4 {
            font-size: 20px;
            margin-bottom: 0px;
        }
    }
}
@media screen and (min-width: 992px) {
    .TableZawodySpan {
        width: 350px;
    }
    .TableDataSpan {
        width: 100px;
    }
    .searchCard {
        width: 400px;
        font-size: 12px;
    }
}
@media screen and (min-width: 1200px) {
    .TableZawodySpan {
        width: 650px;
    }
    .TableDataSpan {
        width: 100px;
    }
    .searchCard {
        font-size: 13px;
        width: 410px;
        margin-left: 1px;
        margin-right: 1px;
        min-height: 180px;
    }
}

.dupalekClose {
    background-color: #449d44 !important;

    background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
        center/1em auto no-repeat !important;
    background: rgba(255, 255, 255, 0.8);
    border: solid 1px !important;
    border-radius: 0.375rem !important;
    width: 1em !important;
    height: 1em !important;
    padding: 0.25em 0.25em !important;
}
.NazwaZawodow {
    font-size: 30px;
    font-weight: bold;
}
.MiejsceZawodow {
    font-size: 14px;
    font-weight: bold;
}
.DataZawodow {
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
}
.KomunikatZawodow {
    font-size: 14px;
    font-weight: bold;
}
.koko_img {
    vertical-align: text-bottom;
}

.searchCard {
    color: #1f4684;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 5px;
    margin-top: 5px;

    padding: 5px;
    a {
        margin-top: 5px;
    }
}
.ZawodnikContainer {
    margin-top: 10px;
}
.zawodnikAvatar {
    padding: 5px;
}
.ZawodnikKlub {
    margin-top: 3px;
}
.searchBlock {
    margin-top: 10px;
    background-color: #dee5ee;
    border: solid 1px #daf7f7;
    border-radius: 0.375rem !important;
    padding: 20px;
    margin-bottom: 5px;
}
.resultsBlock {
    margin-top: 10px;
    background-color: #dee5ee;
    border: solid 1px #daf7f7;
    border-radius: 0.375rem !important;
    padding: 10px;
}
.naglowekBlock {
    margin-top: 10px;
    background-color: #dee5ee;
    border: solid 1px #daf7f7;
    border-radius: 0.375rem !important;
    padding: 20px;
    margin-bottom: 5px;
}
a:not(.btn) {
    color: #1f4684;
    text-decoration: none;

    // .button{
    //     text-decoration: none;
    //     color: white;
    //     font-weight: bold;
    //     font-size: 20px;
    //     position: relative;
    //     padding: 10px;
    //   }
}

a:hover:not(.btn) {
    color: #1f4684;
    text-decoration: none;
}
a:focus:not(.btn) {
    color: #1f4684 !important;
}

a:not(.btn):before,
a:not(.btn):after {
    opacity: 0;
    transition: all 0.3s ease;
    font-size: 20px;
}
a:not(.btn):before {
    content: '[';
}
a:not(.btn):after {
    content: ']';
}

a:not(.btn):hover:before {
    margin-right: 10px;
    content: '[';
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 1;
}
a:not(.btn):hover:after {
    margin-left: 10px;
    content: ']';
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 1;
}
.tablecellCenter {
    display: flex;
    align-items: center;
    text-align: center;
}
.vertical-center {
    vertical-align: middle;
}
