.ReactTable {
    border: 1px solid #1f4684;
}

.ReactTable .-pagination .-btn {
    color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#5cb85c), to(#449d44));
    background-image: -webkit-linear-gradient(top, #5cb85c 0%, #449d44 100%);
    background-image: linear-gradient(to bottom, #5cb85c 0%, #449d44 100%);
    border-color: #398439;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#5cb85c), to(#449d44));
    background-image: -webkit-linear-gradient(top, #5cb85c 0%, #449d44 100%);
    background-image: linear-gradient(to bottom, #5cb85c 0%, #449d44 100%);
    border-color: #398439;
    color: #fff;
}

.ReactTable .rt-thead {
    background-color: #1f4684;
    background-image: -webkit-gradient(linear, left 0%, left 100%, from(#1f4684), to(#1f4684));
    background-image: -moz-linear-gradient(top, #1f4684 0%, #1f4684 100%);
    background-image: linear-gradient(to bottom, #1f4684 0%, #1f4684 100%);
    border-color: #1f4684;
    color: white;
}

.ReactTable .rt-thead .rt-th {
    border-right: 1px solid white;
}

.ReactTable .rt-tbody .rt-td {
    border-right: 1px solid black;
}
